import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { StaticInfo } from '../_helpers/static-info';
import { ClaimStepsService } from '../claim-steps.service';
import cssVars from 'css-vars-ponyfill';
import { ClaimChannel } from '../enum-claim-channel';
import { LossType } from '../claim-data/enum-losstype';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimformService } from '../claimform.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { FraudLanguageComponent } from '../fraud-language/fraud-language.component';
import { FormDocumentComponent } from '../form-document/form-document.component';
import { FormCommunicateComponent } from '../form-communicate/form-communicate.component';
import { FormOtherBenefitsComponent } from '../form-other-benefits/form-other-benefits.component';
import { FormLifeHospComponent } from '../form-life-hosp/form-life-hosp.component';
import { FormContactComponent } from '../form-contact/form-contact.component';
import { MustMatchDirective } from '../_helpers/custom-validators/must-match.directive';
import { NgIf, NgFor } from '@angular/common';
import { FormStepsComponent } from '../form-steps/form-steps.component';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-claimform-contact',
    templateUrl: './claimform-contact.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./claimform-contact.component.css'],
    imports: [FormStepsComponent, NgIf, FormsModule, MustMatchDirective, FormContactComponent, FormLifeHospComponent, FormOtherBenefitsComponent, FormCommunicateComponent, FormDocumentComponent, FraudLanguageComponent, NgSelectModule]
})
export class ClaimformContactComponent implements OnInit {

  @Input() claimForm: NgForm

  contactPage = 0;
  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  errMsg = this.staticInfo.errorMessage;
  memberRegistrationPhoneNumber = this.staticInfo.contactUsPhoneNumber.memberRegistrationClaimChannel
  claimChannel = ClaimChannel;
  lossType = LossType;
  allowHospitalizationLossType: boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowHospitalizationLossType);

  contactSubmit(isFormValid: boolean){
    if(isFormValid){
      if (this.claimService.isLossTypeDisability || this.claimService.lossType == this.claimService.enumLossType.Unemployment){
        this.claimRouterService.contactSubmit(isFormValid);
      } else {
        this.claimformService.submitClaimSubscribe();
      }
    }
  }

  setLossTypeFromMemberRegistration(lossType) {
    this.claimService.setEnumLossType(lossType);
    this.claimService.setPropertiesForCurrentLossType();
  }

  constructor(
    public claimService: ClaimService,
    public claimStepsService: ClaimStepsService,
    public claimRouterService: ClaimRouterService,
    public claimformService: ClaimformService,
    private configService: ConfigurationService
  ) { }

  ngOnInit() {
    cssVars();
    this.claimStepsService.index = this.contactPage;
  }
}
