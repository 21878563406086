import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i3 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
const _c0 = a0 => ({
  "p-steps p-component": true,
  "p-readonly": a0
});
const _c1 = (a0, a1) => ({
  "p-highlight p-steps-current": a0,
  "p-disabled": a1
});
const _c2 = () => ({
  exact: false
});
function Steps_li_2_a_2_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r3.label);
  }
}
function Steps_li_2_a_2_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 13);
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("innerHTML", item_r3.label, i0.ɵɵsanitizeHtml);
  }
}
function Steps_li_2_a_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 9);
    i0.ɵɵlistener("click", function Steps_li_2_a_2_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      const item_r3 = ctx_r1.$implicit;
      const i_r4 = ctx_r1.index;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.itemClick($event, item_r3, i_r4));
    })("keydown.enter", function Steps_li_2_a_2_Template_a_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      const item_r3 = ctx_r1.$implicit;
      const i_r4 = ctx_r1.index;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.itemClick($event, item_r3, i_r4));
    });
    i0.ɵɵelementStart(1, "span", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, Steps_li_2_a_2_span_3_Template, 2, 1, "span", 11)(4, Steps_li_2_a_2_ng_template_4_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const htmlLabel_r6 = i0.ɵɵreference(5);
    const ctx_r1 = i0.ɵɵnextContext();
    const item_r3 = ctx_r1.$implicit;
    const i_r4 = ctx_r1.index;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", item_r3.routerLink)("queryParams", item_r3.queryParams)("routerLinkActive", "p-menuitem-link-active")("routerLinkActiveOptions", item_r3.routerLinkActiveOptions || i0.ɵɵpureFunction0(16, _c2))("target", item_r3.target)("fragment", item_r3.fragment)("queryParamsHandling", item_r3.queryParamsHandling)("preserveFragment", item_r3.preserveFragment)("skipLocationChange", item_r3.skipLocationChange)("replaceUrl", item_r3.replaceUrl)("state", item_r3.state);
    i0.ɵɵattribute("id", item_r3.id)("tabindex", item_r3.disabled || ctx_r4.readonly ? null : item_r3.tabindex ? item_r3.tabindex : "0");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i_r4 + 1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r3.escape !== false)("ngIfElse", htmlLabel_r6);
  }
}
function Steps_li_2_ng_template_3_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(item_r3.label);
  }
}
function Steps_li_2_ng_template_3_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 13);
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("innerHTML", item_r3.label, i0.ɵɵsanitizeHtml);
  }
}
function Steps_li_2_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 14);
    i0.ɵɵlistener("click", function Steps_li_2_ng_template_3_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r1 = i0.ɵɵnextContext();
      const item_r3 = ctx_r1.$implicit;
      const i_r4 = ctx_r1.index;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.itemClick($event, item_r3, i_r4));
    })("keydown.enter", function Steps_li_2_ng_template_3_Template_a_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r1 = i0.ɵɵnextContext();
      const item_r3 = ctx_r1.$implicit;
      const i_r4 = ctx_r1.index;
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.itemClick($event, item_r3, i_r4));
    });
    i0.ɵɵelementStart(1, "span", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, Steps_li_2_ng_template_3_span_3_Template, 2, 1, "span", 11)(4, Steps_li_2_ng_template_3_ng_template_4_Template, 1, 1, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const htmlRouteLabel_r8 = i0.ɵɵreference(5);
    const ctx_r1 = i0.ɵɵnextContext();
    const item_r3 = ctx_r1.$implicit;
    const i_r4 = ctx_r1.index;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("target", item_r3.target);
    i0.ɵɵattribute("href", item_r3.url, i0.ɵɵsanitizeUrl)("id", item_r3.id)("tabindex", item_r3.disabled || i_r4 !== ctx_r4.activeIndex && ctx_r4.readonly ? null : item_r3.tabindex ? item_r3.tabindex : "0");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i_r4 + 1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r3.escape !== false)("ngIfElse", htmlRouteLabel_r8);
  }
}
function Steps_li_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 7, 0);
    i0.ɵɵtemplate(2, Steps_li_2_a_2_Template, 6, 17, "a", 8)(3, Steps_li_2_ng_template_3_Template, 6, 7, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const i_r4 = ctx.index;
    const elseBlock_r9 = i0.ɵɵreference(4);
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(item_r3.styleClass);
    i0.ɵɵproperty("ngStyle", item_r3.style)("tooltipOptions", item_r3.tooltipOptions)("ngClass", i0.ɵɵpureFunction2(9, _c1, ctx_r4.isActive(item_r3, i_r4), item_r3.disabled || ctx_r4.readonly && !ctx_r4.isActive(item_r3, i_r4)));
    i0.ɵɵattribute("aria-selected", i_r4 === ctx_r4.activeIndex)("aria-expanded", i_r4 === ctx_r4.activeIndex);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r4.isClickableRouterLink(item_r3))("ngIfElse", elseBlock_r9);
  }
}
class Steps {
  constructor(router, route, cd) {
    this.router = router;
    this.route = route;
    this.cd = cd;
    this.activeIndex = 0;
    this.readonly = true;
    this.activeIndexChange = new EventEmitter();
  }
  ngOnInit() {
    this.subscription = this.router.events.subscribe(() => this.cd.markForCheck());
  }
  itemClick(event, item, i) {
    if (this.readonly || item.disabled) {
      event.preventDefault();
      return;
    }
    this.activeIndexChange.emit(i);
    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
        index: i
      });
    }
  }
  isClickableRouterLink(item) {
    return item.routerLink && !this.readonly && !item.disabled;
  }
  isActive(item, index) {
    if (item.routerLink) {
      let routerLink = Array.isArray(item.routerLink) ? item.routerLink : [item.routerLink];
      return this.router.isActive(this.router.createUrlTree(routerLink, {
        relativeTo: this.route
      }).toString(), false);
    }
    return index === this.activeIndex;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
Steps.ɵfac = function Steps_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Steps)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
Steps.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Steps,
  selectors: [["p-steps"]],
  hostAttrs: [1, "p-element"],
  inputs: {
    activeIndex: "activeIndex",
    model: "model",
    readonly: "readonly",
    style: "style",
    styleClass: "styleClass"
  },
  outputs: {
    activeIndexChange: "activeIndexChange"
  },
  standalone: false,
  decls: 3,
  vars: 7,
  consts: [["menuitem", ""], ["elseBlock", ""], ["htmlLabel", ""], ["htmlRouteLabel", ""], [3, "ngClass", "ngStyle"], ["role", "tablist"], ["class", "p-steps-item", "role", "tab", "pTooltip", "", 3, "ngStyle", "class", "tooltipOptions", "ngClass", 4, "ngFor", "ngForOf"], ["role", "tab", "pTooltip", "", 1, "p-steps-item", 3, "ngStyle", "tooltipOptions", "ngClass"], ["role", "presentation", "class", "p-menuitem-link", 3, "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "click", "keydown.enter", 4, "ngIf", "ngIfElse"], ["role", "presentation", 1, "p-menuitem-link", 3, "click", "keydown.enter", "routerLink", "queryParams", "routerLinkActive", "routerLinkActiveOptions", "target", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state"], [1, "p-steps-number"], ["class", "p-steps-title", 4, "ngIf", "ngIfElse"], [1, "p-steps-title"], [1, "p-steps-title", 3, "innerHTML"], ["role", "presentation", 1, "p-menuitem-link", 3, "click", "keydown.enter", "target"]],
  template: function Steps_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 4)(1, "ul", 5);
      i0.ɵɵtemplate(2, Steps_li_2_Template, 5, 12, "li", 6);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, ctx.readonly))("ngStyle", ctx.style);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngForOf", ctx.model);
    }
  },
  dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgStyle, i1.RouterLink, i1.RouterLinkActive, i3.Tooltip],
  styles: [".p-steps{position:relative}.p-steps ul{padding:0;margin:0;list-style-type:none;display:flex}.p-steps-item{position:relative;display:flex;justify-content:center;flex:1 1 auto}.p-steps-item .p-menuitem-link{display:inline-flex;flex-direction:column;align-items:center;overflow:hidden;text-decoration:none}.p-steps.p-steps-readonly .p-steps-item{cursor:auto}.p-steps-item.p-steps-current .p-menuitem-link{cursor:default}.p-steps-title{white-space:nowrap}.p-steps-number{display:flex;align-items:center;justify-content:center}.p-steps-title{display:block}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Steps, [{
    type: Component,
    args: [{
      selector: 'p-steps',
      template: `
        <div [ngClass]="{ 'p-steps p-component': true, 'p-readonly': readonly }" [ngStyle]="style" [class]="styleClass">
            <ul role="tablist">
                <li
                    *ngFor="let item of model; let i = index"
                    class="p-steps-item"
                    #menuitem
                    [ngStyle]="item.style"
                    [class]="item.styleClass"
                    role="tab"
                    [attr.aria-selected]="i === activeIndex"
                    [attr.aria-expanded]="i === activeIndex"
                    pTooltip
                    [tooltipOptions]="item.tooltipOptions"
                    [ngClass]="{ 'p-highlight p-steps-current': isActive(item, i), 'p-disabled': item.disabled || (readonly && !isActive(item, i)) }"
                >
                    <a
                        *ngIf="isClickableRouterLink(item); else elseBlock"
                        [routerLink]="item.routerLink"
                        [queryParams]="item.queryParams"
                        role="presentation"
                        [routerLinkActive]="'p-menuitem-link-active'"
                        [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                        class="p-menuitem-link"
                        (click)="itemClick($event, item, i)"
                        (keydown.enter)="itemClick($event, item, i)"
                        [target]="item.target"
                        [attr.id]="item.id"
                        [attr.tabindex]="item.disabled || readonly ? null : item.tabindex ? item.tabindex : '0'"
                        [fragment]="item.fragment"
                        [queryParamsHandling]="item.queryParamsHandling"
                        [preserveFragment]="item.preserveFragment"
                        [skipLocationChange]="item.skipLocationChange"
                        [replaceUrl]="item.replaceUrl"
                        [state]="item.state"
                    >
                        <span class="p-steps-number">{{ i + 1 }}</span>
                        <span class="p-steps-title" *ngIf="item.escape !== false; else htmlLabel">{{ item.label }}</span>
                        <ng-template #htmlLabel><span class="p-steps-title" [innerHTML]="item.label"></span></ng-template>
                    </a>
                    <ng-template #elseBlock>
                        <a
                            [attr.href]="item.url"
                            class="p-menuitem-link"
                            role="presentation"
                            (click)="itemClick($event, item, i)"
                            (keydown.enter)="itemClick($event, item, i)"
                            [target]="item.target"
                            [attr.id]="item.id"
                            [attr.tabindex]="item.disabled || (i !== activeIndex && readonly) ? null : item.tabindex ? item.tabindex : '0'"
                        >
                            <span class="p-steps-number">{{ i + 1 }}</span>
                            <span class="p-steps-title" *ngIf="item.escape !== false; else htmlRouteLabel">{{ item.label }}</span>
                            <ng-template #htmlRouteLabel><span class="p-steps-title" [innerHTML]="item.label"></span></ng-template>
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-steps{position:relative}.p-steps ul{padding:0;margin:0;list-style-type:none;display:flex}.p-steps-item{position:relative;display:flex;justify-content:center;flex:1 1 auto}.p-steps-item .p-menuitem-link{display:inline-flex;flex-direction:column;align-items:center;overflow:hidden;text-decoration:none}.p-steps.p-steps-readonly .p-steps-item{cursor:auto}.p-steps-item.p-steps-current .p-menuitem-link{cursor:default}.p-steps-title{white-space:nowrap}.p-steps-number{display:flex;align-items:center;justify-content:center}.p-steps-title{display:block}\n"]
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: i1.ActivatedRoute
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    activeIndex: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    activeIndexChange: [{
      type: Output
    }]
  });
})();
class StepsModule {}
StepsModule.ɵfac = function StepsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StepsModule)();
};
StepsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StepsModule
});
StepsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, RouterModule, TooltipModule, RouterModule, TooltipModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, TooltipModule],
      exports: [Steps, RouterModule, TooltipModule],
      declarations: [Steps]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Steps, StepsModule };

