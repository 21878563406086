import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { ClaimformService } from '../claimform.service';
import { FindYourCreditUnionComponent } from '../find-your-credit-union/find-your-credit-union.component';
import { FindYourClaimComponent } from '../find-your-claim/find-your-claim.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-landing-file-or-view',
    templateUrl: './landing-file-or-view.component.html',
    styleUrls: ['./landing-file-or-view.component.css'],
    imports: [NgClass, NgIf, FindYourClaimComponent, FindYourCreditUnionComponent]
})
export class LandingFileOrViewComponent implements OnInit {

  viewClaim:boolean;
  fileClaim:boolean;

  activateViewClaim(){
    this.claimService.resetErrorMsg();
    this.viewClaim = true;
    this.fileClaim = false;
  }

  activateFileClaim(){
    this.claimService.resetErrorMsg();
    this.viewClaim = false;
    this.fileClaim = true
  }

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService
  ) { }

  ngOnInit() {
  }

}
