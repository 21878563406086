import * as i0 from '@angular/core';
import { Injectable, InjectionToken, Component, HostListener, EventEmitter, Directive, Optional, Inject, Input, Output, NgModule } from '@angular/core';
import { document, Utils, window as window$1 } from 'ngx-bootstrap/utils';
import * as i2 from 'ngx-bootstrap/focus-trap';
import { FocusTrapModule } from 'ngx-bootstrap/focus-trap';
import * as i1 from 'ngx-bootstrap/component-loader';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _c0 = ["*"];
class BsModalRef {
  constructor() {
    /**
     * Hides the modal
     */
    this.hide = () => void 0;
    /**
     * Sets new class to modal window
     */
    this.setClass = () => void 0;
  }
  static {
    this.ɵfac = function BsModalRef_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsModalRef)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsModalRef,
      factory: BsModalRef.ɵfac,
      providedIn: 'platform'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsModalRef, [{
    type: Injectable,
    args: [{
      providedIn: 'platform'
    }]
  }], null, null);
})();
class ModalBackdropOptions {
  constructor(options) {
    this.animate = true;
    Object.assign(this, options);
  }
}
class ModalOptions {
  static {
    this.ɵfac = function ModalOptions_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalOptions)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ModalOptions,
      factory: ModalOptions.ɵfac,
      providedIn: 'platform'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalOptions, [{
    type: Injectable,
    args: [{
      providedIn: 'platform'
    }]
  }], null, null);
})();
const modalConfigDefaults = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: '',
  animated: true,
  initialState: {},
  closeInterceptor: void 0
};
const MODAL_CONFIG_DEFAULT_OVERRIDE = new InjectionToken('override-default-config');
const CLASS_NAME = {
  SCROLLBAR_MEASURER: 'modal-scrollbar-measure',
  BACKDROP: 'modal-backdrop',
  OPEN: 'modal-open',
  FADE: 'fade',
  IN: 'in',
  SHOW: 'show'
};
const SELECTOR = {
  DIALOG: '.modal-dialog',
  DATA_TOGGLE: '[data-toggle="modal"]',
  DATA_DISMISS: '[data-dismiss="modal"]',
  FIXED_CONTENT: '.navbar-fixed-top, .navbar-fixed-bottom, .is-fixed'
};
const TRANSITION_DURATIONS = {
  MODAL: 300,
  BACKDROP: 150
};
const DISMISS_REASONS = {
  BACKRDOP: 'backdrop-click',
  ESC: 'esc',
  BACK: 'browser-back-navigation-clicked'
};
class ModalContainerComponent {
  constructor(options, _element, _renderer) {
    this._element = _element;
    this._renderer = _renderer;
    this.isShown = false;
    this.isAnimated = false;
    this._focusEl = null;
    this.isModalHiding = false;
    this.clickStartedInContent = false;
    this.config = Object.assign({}, options);
  }
  ngOnInit() {
    this._focusEl = document.activeElement;
    if (this.isAnimated) {
      this._renderer.addClass(this._element.nativeElement, CLASS_NAME.FADE);
    }
    this._renderer.setStyle(this._element.nativeElement, 'display', 'block');
    setTimeout(() => {
      this.isShown = true;
      this._renderer.addClass(this._element.nativeElement, CLASS_NAME.SHOW);
    }, this.isAnimated ? TRANSITION_DURATIONS.BACKDROP : 0);
    if (document && document.body) {
      if (this.bsModalService && this.bsModalService.getModalsCount() === 1) {
        this.bsModalService.checkScrollbar();
        this.bsModalService.setScrollbar();
      }
      this._renderer.addClass(document.body, CLASS_NAME.OPEN);
      this._renderer.setStyle(document.body, 'overflow-y', 'hidden');
    }
    if (this._element.nativeElement) {
      this._element.nativeElement.focus();
    }
  }
  onClickStarted(event) {
    this.clickStartedInContent = event.target !== this._element.nativeElement;
  }
  onClickStop(event) {
    const clickedInBackdrop = event.target === this._element.nativeElement && !this.clickStartedInContent;
    if (this.config.ignoreBackdropClick || this.config.backdrop === 'static' || !clickedInBackdrop) {
      this.clickStartedInContent = false;
      return;
    }
    this.bsModalService?.setDismissReason(DISMISS_REASONS.BACKRDOP);
    this.hide();
  }
  onPopState() {
    this.bsModalService?.setDismissReason(DISMISS_REASONS.BACK);
    this.hide();
  }
  onEsc(event) {
    if (!this.isShown) {
      return;
    }
    if (event.keyCode === 27 || event.key === 'Escape') {
      event.preventDefault();
    }
    if (this.config.keyboard && this.level === this.bsModalService?.getModalsCount()) {
      this.bsModalService?.setDismissReason(DISMISS_REASONS.ESC);
      this.hide();
    }
  }
  ngOnDestroy() {
    if (this.isShown) {
      this._hide();
    }
  }
  hide() {
    if (this.isModalHiding) {
      return;
    }
    if (this.config.closeInterceptor) {
      this.config.closeInterceptor().then(() => this._hide(), () => undefined);
      return;
    }
    this._hide();
  }
  _hide() {
    this.isModalHiding = true;
    this._renderer.removeClass(this._element.nativeElement, CLASS_NAME.SHOW);
    setTimeout(() => {
      this.isShown = false;
      if (document && document.body && this.bsModalService?.getModalsCount() === 1) {
        this._renderer.removeClass(document.body, CLASS_NAME.OPEN);
        this._renderer.setStyle(document.body, 'overflow-y', '');
      }
      this.bsModalService?.hide(this.config.id);
      this.isModalHiding = false;
      if (this._focusEl) {
        this._focusEl.focus();
      }
    }, this.isAnimated ? TRANSITION_DURATIONS.MODAL : 0);
  }
  static {
    this.ɵfac = function ModalContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalContainerComponent)(i0.ɵɵdirectiveInject(ModalOptions), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ModalContainerComponent,
      selectors: [["modal-container"]],
      hostAttrs: ["role", "dialog", "tabindex", "-1", 1, "modal"],
      hostVars: 3,
      hostBindings: function ModalContainerComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown", function ModalContainerComponent_mousedown_HostBindingHandler($event) {
            return ctx.onClickStarted($event);
          })("click", function ModalContainerComponent_click_HostBindingHandler($event) {
            return ctx.onClickStop($event);
          })("popstate", function ModalContainerComponent_popstate_HostBindingHandler() {
            return ctx.onPopState();
          }, false, i0.ɵɵresolveWindow)("keydown.esc", function ModalContainerComponent_keydown_esc_HostBindingHandler($event) {
            return ctx.onEsc($event);
          }, false, i0.ɵɵresolveWindow);
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-modal", true)("aria-labelledby", ctx.config.ariaLabelledBy)("aria-describedby", ctx.config.ariaDescribedby);
        }
      },
      standalone: false,
      ngContentSelectors: _c0,
      decls: 3,
      vars: 2,
      consts: [["role", "document", "focusTrap", ""], [1, "modal-content"]],
      template: function ModalContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMap("modal-dialog" + (ctx.config.class ? " " + ctx.config.class : ""));
        }
      },
      dependencies: [i2.FocusTrapDirective],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalContainerComponent, [{
    type: Component,
    args: [{
      selector: 'modal-container',
      template: `
    <div [class]="'modal-dialog' + (config.class ? ' ' + config.class : '')"
         role="document"
         focusTrap>
      <div class="modal-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: 'modal',
        role: 'dialog',
        tabindex: '-1',
        '[attr.aria-modal]': 'true',
        '[attr.aria-labelledby]': 'config.ariaLabelledBy',
        '[attr.aria-describedby]': 'config.ariaDescribedby'
      }
    }]
  }], function () {
    return [{
      type: ModalOptions
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    onClickStarted: [{
      type: HostListener,
      args: ['mousedown', ['$event']]
    }],
    onClickStop: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onPopState: [{
      type: HostListener,
      args: ['window:popstate']
    }],
    onEsc: [{
      type: HostListener,
      args: ['window:keydown.esc', ['$event']]
    }]
  });
})();

/** This component will be added as background layout for modals if enabled */
class ModalBackdropComponent {
  get isAnimated() {
    return this._isAnimated;
  }
  set isAnimated(value) {
    this._isAnimated = value;
  }
  get isShown() {
    return this._isShown;
  }
  set isShown(value) {
    this._isShown = value;
    if (value) {
      this.renderer.addClass(this.element.nativeElement, `${CLASS_NAME.SHOW}`);
    } else {
      this.renderer.removeClass(this.element.nativeElement, `${CLASS_NAME.SHOW}`);
    }
  }
  constructor(element, renderer) {
    this._isAnimated = false;
    this._isShown = false;
    this.element = element;
    this.renderer = renderer;
  }
  ngOnInit() {
    if (this.isAnimated) {
      this.renderer.addClass(this.element.nativeElement, `${CLASS_NAME.FADE}`);
      Utils.reflow(this.element.nativeElement);
    }
    this.isShown = true;
  }
  static {
    this.ɵfac = function ModalBackdropComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalBackdropComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ModalBackdropComponent,
      selectors: [["bs-modal-backdrop"]],
      hostAttrs: [1, "modal-backdrop"],
      standalone: false,
      decls: 0,
      vars: 0,
      template: function ModalBackdropComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalBackdropComponent, [{
    type: Component,
    args: [{
      selector: 'bs-modal-backdrop',
      template: ' ',
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        class: CLASS_NAME.BACKDROP
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();

// todo: should we support enforce focus in?
// todo: in original bs there are was a way to prevent modal from showing
// todo: original modal had resize events
const TRANSITION_DURATION = 300;
const BACKDROP_TRANSITION_DURATION = 150;
/** Mark any code with directive to show it's content in modal */
class ModalDirective {
  /** allows to set modal configuration via element property */
  set config(conf) {
    this._config = this.getConfig(conf);
  }
  get config() {
    return this._config;
  }
  get isShown() {
    return this._isShown;
  }
  constructor(_element, _viewContainerRef, _renderer, clf, modalDefaultOption) {
    this._element = _element;
    this._renderer = _renderer;
    /** This event fires immediately when the `show` instance method is called. */
    this.onShow = new EventEmitter();
    /** This event is fired when the modal has been made visible to the user
     * (will wait for CSS transitions to complete)
     */
    this.onShown = new EventEmitter();
    /** This event is fired immediately when
     * the hide instance method has been called.
     */
    this.onHide = new EventEmitter();
    /** This event is fired when the modal has finished being
     * hidden from the user (will wait for CSS transitions to complete).
     */
    this.onHidden = new EventEmitter();
    this._isShown = false;
    this.isBodyOverflowing = false;
    this.originalBodyPadding = 0;
    this.scrollbarWidth = 0;
    this.timerHideModal = 0;
    this.timerRmBackDrop = 0;
    this.isNested = false;
    this.clickStartedInContent = false;
    this._focusEl = null;
    this._backdrop = clf.createLoader(_element, _viewContainerRef, _renderer);
    this._config = modalDefaultOption || modalConfigDefaults;
  }
  onClickStarted(event) {
    this.clickStartedInContent = event.target !== this._element.nativeElement;
  }
  onClickStop(event) {
    const clickedInBackdrop = event.target === this._element.nativeElement && !this.clickStartedInContent;
    if (this.config.ignoreBackdropClick || this.config.backdrop === 'static' || !clickedInBackdrop) {
      this.clickStartedInContent = false;
      return;
    }
    this.dismissReason = DISMISS_REASONS.BACKRDOP;
    this.hide(event);
  }
  // todo: consider preventing default and stopping propagation
  onEsc(event) {
    if (!this._isShown) {
      return;
    }
    if (event.keyCode === 27 || event.key === 'Escape') {
      event.preventDefault();
    }
    if (this.config.keyboard) {
      this.dismissReason = DISMISS_REASONS.ESC;
      this.hide();
    }
  }
  ngOnDestroy() {
    if (this._isShown) {
      this._isShown = false;
      this.hideModal();
      this._backdrop.dispose();
    }
  }
  ngOnInit() {
    this._config = this._config || this.getConfig();
    setTimeout(() => {
      if (this._config.show) {
        this.show();
      }
    }, 0);
  }
  /* Public methods */
  /** Allows to manually toggle modal visibility */
  toggle() {
    return this._isShown ? this.hide() : this.show();
  }
  /** Allows to manually open modal */
  show() {
    this.dismissReason = void 0;
    this.onShow.emit(this);
    if (this._isShown) {
      return;
    }
    clearTimeout(this.timerHideModal);
    clearTimeout(this.timerRmBackDrop);
    this._isShown = true;
    this.checkScrollbar();
    this.setScrollbar();
    if (document && document.body) {
      if (document.body.classList.contains(CLASS_NAME.OPEN)) {
        this.isNested = true;
      } else {
        this._renderer.addClass(document.body, CLASS_NAME.OPEN);
        this._renderer.setStyle(document.body, 'overflow-y', 'hidden');
      }
    }
    this.showBackdrop(() => {
      this.showElement();
    });
  }
  /** Check if we can close the modal */
  hide(event) {
    if (!this._isShown) {
      return;
    }
    if (event) {
      event.preventDefault();
    }
    if (this.config.closeInterceptor) {
      this.config.closeInterceptor().then(() => this._hide(), () => undefined);
      return;
    }
    this._hide();
  }
  /** Private methods @internal */
  /**
   *  Manually close modal
   *  @internal
   */
  _hide() {
    this.onHide.emit(this);
    window$1.clearTimeout(this.timerHideModal);
    window$1.clearTimeout(this.timerRmBackDrop);
    this._isShown = false;
    this._renderer.removeClass(this._element.nativeElement, CLASS_NAME.SHOW);
    if (this._config.animated) {
      this.timerHideModal = window$1.setTimeout(() => this.hideModal(), TRANSITION_DURATION);
    } else {
      this.hideModal();
    }
    if (this._focusEl) {
      this._focusEl.focus();
    }
  }
  getConfig(config) {
    return Object.assign({}, this._config, config);
  }
  /**
   *  Show dialog
   *  @internal
   */
  showElement() {
    // todo: replace this with component loader usage
    if (!this._element.nativeElement.parentNode || this._element.nativeElement.parentNode.nodeType !== Node.ELEMENT_NODE) {
      // don't move modals dom position
      if (document && document.body) {
        document.body.appendChild(this._element.nativeElement);
      }
    }
    this._renderer.setAttribute(this._element.nativeElement, 'aria-hidden', 'false');
    this._renderer.setAttribute(this._element.nativeElement, 'aria-modal', 'true');
    this._renderer.setStyle(this._element.nativeElement, 'display', 'block');
    this._renderer.setProperty(this._element.nativeElement, 'scrollTop', 0);
    if (this._config.animated) {
      Utils.reflow(this._element.nativeElement);
    }
    this._renderer.addClass(this._element.nativeElement, CLASS_NAME.SHOW);
    const transitionComplete = () => {
      if (this._config.focus) {
        this._element.nativeElement.focus();
      }
      this.onShown.emit(this);
    };
    if (this._config.animated) {
      setTimeout(transitionComplete, TRANSITION_DURATION);
    } else {
      transitionComplete();
    }
  }
  /** @internal */
  hideModal() {
    this._renderer.setAttribute(this._element.nativeElement, 'aria-hidden', 'true');
    this._renderer.setStyle(this._element.nativeElement, 'display', 'none');
    this.showBackdrop(() => {
      if (!this.isNested) {
        if (document && document.body) {
          this._renderer.removeClass(document.body, CLASS_NAME.OPEN);
          this._renderer.setStyle(document.body, 'overflow-y', '');
        }
        this.resetScrollbar();
      }
      this.resetAdjustments();
      this.focusOtherModal();
      this.onHidden.emit(this);
    });
  }
  // todo: original show was calling a callback when done, but we can use
  // promise
  /** @internal */
  showBackdrop(callback) {
    if (this._isShown && this.config.backdrop && (!this.backdrop || !this.backdrop.instance.isShown)) {
      this.removeBackdrop();
      this._backdrop.attach(ModalBackdropComponent).to('body').show({
        isAnimated: this._config.animated
      });
      this.backdrop = this._backdrop._componentRef;
      if (!callback) {
        return;
      }
      if (!this._config.animated) {
        callback();
        return;
      }
      setTimeout(callback, BACKDROP_TRANSITION_DURATION);
    } else if (!this._isShown && this.backdrop) {
      this.backdrop.instance.isShown = false;
      const callbackRemove = () => {
        this.removeBackdrop();
        if (callback) {
          callback();
        }
      };
      if (this.backdrop.instance.isAnimated) {
        this.timerRmBackDrop = window$1.setTimeout(callbackRemove, BACKDROP_TRANSITION_DURATION);
      } else {
        callbackRemove();
      }
    } else if (callback) {
      callback();
    }
  }
  /** @internal */
  removeBackdrop() {
    this._backdrop.hide();
  }
  /** Events tricks */
  // no need for it
  // protected setEscapeEvent():void {
  //   if (this._isShown && this._config.keyboard) {
  //     $(this._element).on(Event.KEYDOWN_DISMISS, (event) => {
  //       if (event.which === 27) {
  //         this.hide()
  //       }
  //     })
  //
  //   } else if (!this._isShown) {
  //     $(this._element).off(Event.KEYDOWN_DISMISS)
  //   }
  // }
  // protected setResizeEvent():void {
  // console.log(this.renderer.listenGlobal('', Event.RESIZE));
  // if (this._isShown) {
  //   $(window).on(Event.RESIZE, $.proxy(this._handleUpdate, this))
  // } else {
  //   $(window).off(Event.RESIZE)
  // }
  // }
  focusOtherModal() {
    if (this._element.nativeElement.parentElement == null) {
      return;
    }
    const otherOpenedModals = this._element.nativeElement.parentElement.querySelectorAll('.in[bsModal]');
    if (!otherOpenedModals.length) {
      return;
    }
    otherOpenedModals[otherOpenedModals.length - 1].focus();
  }
  /** @internal */
  resetAdjustments() {
    this._renderer.setStyle(this._element.nativeElement, 'paddingLeft', '');
    this._renderer.setStyle(this._element.nativeElement, 'paddingRight', '');
  }
  /** Scroll bar tricks */
  /** @internal */
  checkScrollbar() {
    this.isBodyOverflowing = document.body.clientWidth < window$1.innerWidth;
    this.scrollbarWidth = this.getScrollbarWidth();
  }
  setScrollbar() {
    if (!document) {
      return;
    }
    this.originalBodyPadding = parseInt(window$1.getComputedStyle(document.body).getPropertyValue('padding-right') || 0, 10);
    if (this.isBodyOverflowing) {
      document.body.style.paddingRight = `${this.originalBodyPadding + this.scrollbarWidth}px`;
    }
  }
  resetScrollbar() {
    document.body.style.paddingRight = `${this.originalBodyPadding}px`;
  }
  // thx d.walsh
  getScrollbarWidth() {
    const scrollDiv = this._renderer.createElement('div');
    this._renderer.addClass(scrollDiv, CLASS_NAME.SCROLLBAR_MEASURER);
    this._renderer.appendChild(document.body, scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    this._renderer.removeChild(document.body, scrollDiv);
    return scrollbarWidth;
  }
  static {
    this.ɵfac = function ModalDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.ComponentLoaderFactory), i0.ɵɵdirectiveInject(MODAL_CONFIG_DEFAULT_OVERRIDE, 8));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ModalDirective,
      selectors: [["", "bsModal", ""]],
      hostBindings: function ModalDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown", function ModalDirective_mousedown_HostBindingHandler($event) {
            return ctx.onClickStarted($event);
          })("mouseup", function ModalDirective_mouseup_HostBindingHandler($event) {
            return ctx.onClickStop($event);
          })("keydown.esc", function ModalDirective_keydown_esc_HostBindingHandler($event) {
            return ctx.onEsc($event);
          });
        }
      },
      inputs: {
        config: "config",
        closeInterceptor: "closeInterceptor"
      },
      outputs: {
        onShow: "onShow",
        onShown: "onShown",
        onHide: "onHide",
        onHidden: "onHidden"
      },
      exportAs: ["bs-modal"],
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalDirective, [{
    type: Directive,
    args: [{
      selector: '[bsModal]',
      exportAs: 'bs-modal'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1.ComponentLoaderFactory
    }, {
      type: ModalOptions,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MODAL_CONFIG_DEFAULT_OVERRIDE]
      }]
    }];
  }, {
    config: [{
      type: Input
    }],
    closeInterceptor: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onShown: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    onHidden: [{
      type: Output
    }],
    onClickStarted: [{
      type: HostListener,
      args: ['mousedown', ['$event']]
    }],
    onClickStop: [{
      type: HostListener,
      args: ['mouseup', ['$event']]
    }],
    onEsc: [{
      type: HostListener,
      args: ['keydown.esc', ['$event']]
    }]
  });
})();
let currentId = 1;
class BsModalService {
  constructor(rendererFactory, clf, modalDefaultOption) {
    this.clf = clf;
    this.modalDefaultOption = modalDefaultOption;
    this.onShow = new EventEmitter();
    this.onShown = new EventEmitter();
    this.onHide = new EventEmitter();
    this.onHidden = new EventEmitter();
    this.isBodyOverflowing = false;
    this.originalBodyPadding = 0;
    this.scrollbarWidth = 0;
    this.modalsCount = 0;
    this.loaders = [];
    this._focusEl = null;
    this._backdropLoader = this.clf.createLoader();
    this._renderer = rendererFactory.createRenderer(null, null);
    this.config = modalDefaultOption ? Object.assign({}, modalConfigDefaults, modalDefaultOption) : modalConfigDefaults;
  }
  /** Shows a modal */
  show(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content, config) {
    this._focusEl = document.activeElement;
    this.modalsCount++;
    this._createLoaders();
    // must be different per every show() call
    const id = config?.id || currentId++;
    this.config = this.modalDefaultOption ? Object.assign({}, modalConfigDefaults, this.modalDefaultOption, config) : Object.assign({}, modalConfigDefaults, config);
    this.config.id = id;
    this._showBackdrop();
    this.lastDismissReason = void 0;
    return this._showModal(content);
  }
  hide(id) {
    if (this.modalsCount === 1 || id == null) {
      this._hideBackdrop();
      this.resetScrollbar();
    }
    this.modalsCount = this.modalsCount >= 1 && id != null ? this.modalsCount - 1 : 0;
    setTimeout(() => {
      this._hideModal(id);
      this.removeLoaders(id);
    }, this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0);
    if (this._focusEl) {
      this._focusEl.focus();
    }
  }
  _showBackdrop() {
    const isBackdropEnabled = this.config.backdrop === true || this.config.backdrop === 'static';
    const isBackdropInDOM = !this.backdropRef || !this.backdropRef.instance.isShown;
    if (this.modalsCount === 1) {
      this.removeBackdrop();
      if (isBackdropEnabled && isBackdropInDOM) {
        this._backdropLoader.attach(ModalBackdropComponent).to('body').show({
          isAnimated: this.config.animated
        });
        this.backdropRef = this._backdropLoader._componentRef;
      }
    }
  }
  _hideBackdrop() {
    if (!this.backdropRef) {
      return;
    }
    this.backdropRef.instance.isShown = false;
    const duration = this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0;
    setTimeout(() => this.removeBackdrop(), duration);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _showModal(content) {
    const modalLoader = this.loaders[this.loaders.length - 1];
    if (this.config && this.config.providers) {
      for (const provider of this.config.providers) {
        modalLoader.provide(provider);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bsModalRef = new BsModalRef();
    const modalContainerRef = modalLoader.provide({
      provide: ModalOptions,
      useValue: this.config
    }).provide({
      provide: BsModalRef,
      useValue: bsModalRef
    }).attach(ModalContainerComponent).to('body');
    bsModalRef.hide = () => this.hide(bsModalRef.id);
    bsModalRef.setClass = newClass => {
      if (modalContainerRef.instance) {
        modalContainerRef.instance.config.class = newClass;
      }
    };
    bsModalRef.onHidden = new EventEmitter();
    bsModalRef.onHide = new EventEmitter();
    this.copyEvent(modalLoader.onBeforeHide, bsModalRef.onHide);
    this.copyEvent(modalLoader.onHidden, bsModalRef.onHidden);
    // call 'show' method after assign setClass in bsModalRef.
    // it makes modal component's bsModalRef available to call setClass method
    modalContainerRef.show({
      content,
      isAnimated: this.config.animated,
      initialState: this.config.initialState,
      bsModalService: this,
      id: this.config.id
    });
    if (modalContainerRef.instance) {
      modalContainerRef.instance.level = this.getModalsCount();
      bsModalRef.content = modalLoader.getInnerComponent();
      bsModalRef.id = modalContainerRef.instance.config?.id;
    }
    return bsModalRef;
  }
  _hideModal(id) {
    if (id != null) {
      const indexToRemove = this.loaders.findIndex(loader => loader.instance?.config.id === id);
      const modalLoader = this.loaders[indexToRemove];
      if (modalLoader) {
        modalLoader.hide(id);
      }
    } else {
      this.loaders.forEach(loader => {
        if (loader.instance) {
          loader.hide(loader.instance.config.id);
        }
      });
    }
  }
  getModalsCount() {
    return this.modalsCount;
  }
  setDismissReason(reason) {
    this.lastDismissReason = reason;
  }
  removeBackdrop() {
    this._renderer.removeClass(document.body, CLASS_NAME.OPEN);
    this._renderer.setStyle(document.body, 'overflow-y', '');
    this._backdropLoader.hide();
    this.backdropRef = void 0;
  }
  /** Checks if the body is overflowing and sets scrollbar width */
  /** @internal */
  checkScrollbar() {
    this.isBodyOverflowing = document.body.clientWidth < window.innerWidth;
    this.scrollbarWidth = this.getScrollbarWidth();
  }
  setScrollbar() {
    if (!document) {
      return;
    }
    this.originalBodyPadding = parseInt(window.getComputedStyle(document.body).getPropertyValue('padding-right') || '0', 10);
    if (this.isBodyOverflowing) {
      document.body.style.paddingRight = `${this.originalBodyPadding + this.scrollbarWidth}px`;
    }
  }
  resetScrollbar() {
    document.body.style.paddingRight = `${this.originalBodyPadding}px`;
  }
  // thx d.walsh
  getScrollbarWidth() {
    const scrollDiv = this._renderer.createElement('div');
    this._renderer.addClass(scrollDiv, CLASS_NAME.SCROLLBAR_MEASURER);
    this._renderer.appendChild(document.body, scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    this._renderer.removeChild(document.body, scrollDiv);
    return scrollbarWidth;
  }
  _createLoaders() {
    const loader = this.clf.createLoader();
    this.copyEvent(loader.onBeforeShow, this.onShow);
    this.copyEvent(loader.onShown, this.onShown);
    this.copyEvent(loader.onBeforeHide, this.onHide);
    this.copyEvent(loader.onHidden, this.onHidden);
    this.loaders.push(loader);
  }
  removeLoaders(id) {
    if (id != null) {
      const indexToRemove = this.loaders.findIndex(loader => loader.instance?.config.id === id);
      if (indexToRemove >= 0) {
        this.loaders.splice(indexToRemove, 1);
        this.loaders.forEach((loader, i) => {
          if (loader.instance) {
            loader.instance.level = i + 1;
          }
        });
      }
    } else {
      this.loaders.splice(0, this.loaders.length);
    }
  }
  copyEvent(from, to) {
    from.subscribe(data => {
      to.emit(this.lastDismissReason || data);
    });
  }
  static {
    this.ɵfac = function BsModalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BsModalService)(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i1.ComponentLoaderFactory), i0.ɵɵinject(MODAL_CONFIG_DEFAULT_OVERRIDE, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BsModalService,
      factory: BsModalService.ɵfac,
      providedIn: 'platform'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BsModalService, [{
    type: Injectable,
    args: [{
      providedIn: 'platform'
    }]
  }], function () {
    return [{
      type: i0.RendererFactory2
    }, {
      type: i1.ComponentLoaderFactory
    }, {
      type: ModalOptions,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MODAL_CONFIG_DEFAULT_OVERRIDE]
      }]
    }];
  }, null);
})();
const focusTrapModule = FocusTrapModule.forRoot();
class ModalModule {
  static forRoot() {
    return {
      ngModule: ModalModule,
      providers: [BsModalService, ComponentLoaderFactory, PositioningService]
    };
  }
  static forChild() {
    return {
      ngModule: ModalModule,
      providers: [BsModalService, ComponentLoaderFactory, PositioningService]
    };
  }
  static {
    this.ɵfac = function ModalModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ModalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [FocusTrapModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalModule, [{
    type: NgModule,
    args: [{
      imports: [FocusTrapModule],
      declarations: [ModalBackdropComponent, ModalDirective, ModalContainerComponent],
      exports: [ModalBackdropComponent, ModalDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BsModalRef, BsModalService, MODAL_CONFIG_DEFAULT_OVERRIDE, ModalBackdropComponent, ModalBackdropOptions, ModalContainerComponent, ModalDirective, ModalModule, ModalOptions };
