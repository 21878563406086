import * as i0 from '@angular/core';
import { forwardRef, Injectable, EventEmitter, Component, ChangeDetectionStrategy, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
const _c0 = ["rb"];
const _c1 = (a0, a1, a2) => ({
  "p-radiobutton p-component": true,
  "p-radiobutton-checked": a0,
  "p-radiobutton-disabled": a1,
  "p-radiobutton-focused": a2
});
const _c2 = (a0, a1, a2) => ({
  "p-radiobutton-box": true,
  "p-highlight": a0,
  "p-disabled": a1,
  "p-focus": a2
});
const _c3 = (a0, a1, a2) => ({
  "p-radiobutton-label": true,
  "p-radiobutton-label-active": a0,
  "p-disabled": a1,
  "p-radiobutton-label-focus": a2
});
function RadioButton_label_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "label", 4);
    i0.ɵɵlistener("click", function RadioButton_label_6_Template_label_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.select($event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    const rb_r2 = i0.ɵɵreference(3);
    i0.ɵɵclassMap(ctx_r3.labelStyleClass);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(5, _c3, rb_r2.checked, ctx_r3.disabled, ctx_r3.focused));
    i0.ɵɵattribute("for", ctx_r3.inputId);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r3.label);
  }
}
const RADIO_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButton),
  multi: true
};
class RadioControlRegistry {
  constructor() {
    this.accessors = [];
  }
  add(control, accessor) {
    this.accessors.push([control, accessor]);
  }
  remove(accessor) {
    this.accessors = this.accessors.filter(c => {
      return c[1] !== accessor;
    });
  }
  select(accessor) {
    this.accessors.forEach(c => {
      if (this.isSameGroup(c, accessor) && c[1] !== accessor) {
        c[1].writeValue(accessor.value);
      }
    });
  }
  isSameGroup(controlPair, accessor) {
    if (!controlPair[0].control) {
      return false;
    }
    return controlPair[0].control.root === accessor.control.control.root && controlPair[1].name === accessor.name;
  }
}
RadioControlRegistry.ɵfac = function RadioControlRegistry_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadioControlRegistry)();
};
RadioControlRegistry.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: RadioControlRegistry,
  factory: RadioControlRegistry.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioControlRegistry, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class RadioButton {
  constructor(cd, injector, registry) {
    this.cd = cd;
    this.injector = injector;
    this.registry = registry;
    this.onClick = new EventEmitter();
    this.onFocus = new EventEmitter();
    this.onBlur = new EventEmitter();
    this.onModelChange = () => {};
    this.onModelTouched = () => {};
  }
  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.checkName();
    this.registry.add(this.control, this);
  }
  handleClick(event, radioButton, focus) {
    event.preventDefault();
    if (this.disabled) {
      return;
    }
    this.select(event);
    if (focus) {
      radioButton.focus();
    }
  }
  select(event) {
    if (!this.disabled) {
      this.inputViewChild.nativeElement.checked = true;
      this.checked = true;
      this.onModelChange(this.value);
      this.registry.select(this);
      this.onClick.emit({
        originalEvent: event,
        value: this.value
      });
    }
  }
  writeValue(value) {
    this.checked = value == this.value;
    if (this.inputViewChild && this.inputViewChild.nativeElement) {
      this.inputViewChild.nativeElement.checked = this.checked;
    }
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  onInputFocus(event) {
    this.focused = true;
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.focused = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }
  onChange(event) {
    this.select(event);
  }
  focus() {
    this.inputViewChild.nativeElement.focus();
  }
  ngOnDestroy() {
    this.registry.remove(this);
  }
  checkName() {
    if (this.name && this.formControlName && this.name !== this.formControlName) {
      this.throwNameError();
    }
    if (!this.name && this.formControlName) {
      this.name = this.formControlName;
    }
  }
  throwNameError() {
    throw new Error(`
          If you define both a name and a formControlName attribute on your radio button, their values
          must match. Ex: <p-radioButton formControlName="food" name="food"></p-radioButton>
        `);
  }
}
RadioButton.ɵfac = function RadioButton_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadioButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(RadioControlRegistry));
};
RadioButton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadioButton,
  selectors: [["p-radioButton"]],
  viewQuery: function RadioButton_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputViewChild = _t.first);
    }
  },
  hostAttrs: [1, "p-element"],
  inputs: {
    value: "value",
    formControlName: "formControlName",
    name: "name",
    disabled: "disabled",
    label: "label",
    tabindex: "tabindex",
    inputId: "inputId",
    ariaLabelledBy: "ariaLabelledBy",
    ariaLabel: "ariaLabel",
    style: "style",
    styleClass: "styleClass",
    labelStyleClass: "labelStyleClass"
  },
  outputs: {
    onClick: "onClick",
    onFocus: "onFocus",
    onBlur: "onBlur"
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([RADIO_VALUE_ACCESSOR])],
  decls: 7,
  vars: 23,
  consts: [["rb", ""], [3, "ngStyle", "ngClass"], [1, "p-hidden-accessible"], ["type", "radio", 3, "change", "focus", "blur", "checked", "disabled"], [3, "click", "ngClass"], [1, "p-radiobutton-icon"], [3, "class", "ngClass", "click", 4, "ngIf"]],
  template: function RadioButton_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "input", 3, 0);
      i0.ɵɵlistener("change", function RadioButton_Template_input_change_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onChange($event));
      })("focus", function RadioButton_Template_input_focus_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onInputFocus($event));
      })("blur", function RadioButton_Template_input_blur_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onInputBlur($event));
      });
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(4, "div", 4);
      i0.ɵɵlistener("click", function RadioButton_Template_div_click_4_listener($event) {
        i0.ɵɵrestoreView(_r1);
        const rb_r2 = i0.ɵɵreference(3);
        return i0.ɵɵresetView(ctx.handleClick($event, rb_r2, true));
      });
      i0.ɵɵelement(5, "span", 5);
      i0.ɵɵelementEnd()();
      i0.ɵɵtemplate(6, RadioButton_label_6_Template, 2, 9, "label", 6);
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", i0.ɵɵpureFunction3(15, _c1, ctx.checked, ctx.disabled, ctx.focused));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("checked", ctx.checked)("disabled", ctx.disabled);
      i0.ɵɵattribute("id", ctx.inputId)("name", ctx.name)("value", ctx.value)("tabindex", ctx.tabindex)("aria-checked", ctx.checked)("aria-label", ctx.ariaLabel)("aria-labelledby", ctx.ariaLabelledBy);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(19, _c2, ctx.checked, ctx.disabled, ctx.focused));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.label);
    }
  },
  dependencies: [i1.NgClass, i1.NgIf, i1.NgStyle],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioButton, [{
    type: Component,
    args: [{
      selector: 'p-radioButton',
      template: `
        <div [ngStyle]="style" [ngClass]="{ 'p-radiobutton p-component': true, 'p-radiobutton-checked': checked, 'p-radiobutton-disabled': disabled, 'p-radiobutton-focused': focused }" [class]="styleClass">
            <div class="p-hidden-accessible">
                <input
                    #rb
                    type="radio"
                    [attr.id]="inputId"
                    [attr.name]="name"
                    [attr.value]="value"
                    [attr.tabindex]="tabindex"
                    [attr.aria-checked]="checked"
                    [attr.aria-label]="ariaLabel"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    [checked]="checked"
                    (change)="onChange($event)"
                    (focus)="onInputFocus($event)"
                    (blur)="onInputBlur($event)"
                    [disabled]="disabled"
                />
            </div>
            <div (click)="handleClick($event, rb, true)" [ngClass]="{ 'p-radiobutton-box': true, 'p-highlight': checked, 'p-disabled': disabled, 'p-focus': focused }">
                <span class="p-radiobutton-icon"></span>
            </div>
        </div>
        <label
            (click)="select($event)"
            [class]="labelStyleClass"
            [ngClass]="{ 'p-radiobutton-label': true, 'p-radiobutton-label-active': rb.checked, 'p-disabled': disabled, 'p-radiobutton-label-focus': focused }"
            *ngIf="label"
            [attr.for]="inputId"
            >{{ label }}</label
        >
    `,
      providers: [RADIO_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.Injector
    }, {
      type: RadioControlRegistry
    }];
  }, {
    value: [{
      type: Input
    }],
    formControlName: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    labelStyleClass: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    inputViewChild: [{
      type: ViewChild,
      args: ['rb']
    }]
  });
})();
class RadioButtonModule {}
RadioButtonModule.ɵfac = function RadioButtonModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadioButtonModule)();
};
RadioButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RadioButtonModule
});
RadioButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [RadioButton],
      declarations: [RadioButton]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RADIO_VALUE_ACCESSOR, RadioButton, RadioButtonModule, RadioControlRegistry };

