import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { ClaimformService } from '../claimform.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-form-description',
    templateUrl: './form-description.component.html',
    styleUrls: ['./form-description.component.css'],
    imports: [FormsModule, InputTextareaModule]
})
export class FormDescriptionComponent implements OnInit {

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService
  ) { }

  ngOnInit() {
  }

}
