import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { FormDescriptionComponent } from '../form-description/form-description.component';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { FormDisabilityDescriptionComponent } from '../form-disability-description/form-disability-description.component';
import { FormProvidersComponent } from '../form-providers/form-providers.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { NgIf, NgFor } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-medical',
    templateUrl: './form-medical.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-medical.component.css'],
    imports: [RadioButtonModule, FormsModule, NgIf, InputTextModule, InputMaskModule, FormProvidersComponent, FormDisabilityDescriptionComponent, CalendarModule, DateMaskDirective, ButtonModule, MessagesModule, FormDescriptionComponent, NgSelectModule]
})
export class FormMedicalComponent implements OnInit {

  @Input() claimForm: NgForm

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService
  ) { }

  ngOnInit() {
    cssVars();
  }

}
