import { Component, OnInit,Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { ClaimformService } from '../claimform.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
    selector: 'app-form-disability-description',
    templateUrl: './form-disability-description.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-disability-description.component.css'],
    imports: [FormsModule, InputTextareaModule, NgIf]
})
export class FormDisabilityDescriptionComponent implements OnInit {
  
  @Input() claimForm: NgForm

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService
  ) { }

  ngOnInit() {
  }

}
