import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, PLATFORM_ID, Directive, Inject, Input, Output, HostListener, NgModule } from '@angular/core';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { NG_VALIDATORS } from '@angular/forms';
const KEYFILTER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => KeyFilter),
  multi: true
};
const DEFAULT_MASKS = {
  pint: /[\d]/,
  int: /[\d\-]/,
  pnum: /[\d\.]/,
  money: /[\d\.\s,]/,
  num: /[\d\-\.]/,
  hex: /[0-9a-f]/i,
  email: /[a-z0-9_\.\-@]/i,
  alpha: /[a-z_]/i,
  alphanum: /[a-z0-9_]/i
};
const KEYS = {
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  BACKSPACE: 8,
  DELETE: 46
};
const SAFARI_KEYS = {
  63234: 37,
  63235: 39,
  63232: 38,
  63233: 40,
  63276: 33,
  63277: 34,
  63272: 46,
  63273: 36,
  63275: 35 // end
};
class KeyFilter {
  constructor(document, platformId, el) {
    this.document = document;
    this.platformId = platformId;
    this.el = el;
    this.ngModelChange = new EventEmitter();
    if (isPlatformBrowser(this.platformId)) {
      this.isAndroid = DomHandler.isAndroid();
    } else {
      this.isAndroid = false;
    }
  }
  get pattern() {
    return this._pattern;
  }
  set pattern(_pattern) {
    this._pattern = _pattern;
    this.regex = DEFAULT_MASKS[this._pattern] || this._pattern;
  }
  isNavKeyPress(e) {
    let k = e.keyCode;
    k = DomHandler.getBrowser().safari ? SAFARI_KEYS[k] || k : k;
    return k >= 33 && k <= 40 || k == KEYS.RETURN || k == KEYS.TAB || k == KEYS.ESC;
  }
  isSpecialKey(e) {
    let k = e.keyCode || e.charCode;
    return k == 9 || k == 13 || k == 27 || k == 16 || k == 17 || k >= 18 && k <= 20 || DomHandler.getBrowser().opera && !e.shiftKey && (k == 8 || k >= 33 && k <= 35 || k >= 36 && k <= 39 || k >= 44 && k <= 45);
  }
  getKey(e) {
    let k = e.keyCode || e.charCode;
    return DomHandler.getBrowser().safari ? SAFARI_KEYS[k] || k : k;
  }
  getCharCode(e) {
    return e.charCode || e.keyCode || e.which;
  }
  findDelta(value, prevValue) {
    let delta = '';
    for (let i = 0; i < value.length; i++) {
      let str = value.substr(0, i) + value.substr(i + value.length - prevValue.length);
      if (str === prevValue) delta = value.substr(i, value.length - prevValue.length);
    }
    return delta;
  }
  isValidChar(c) {
    return this.regex.test(c);
  }
  isValidString(str) {
    for (let i = 0; i < str.length; i++) {
      if (!this.isValidChar(str.substr(i, 1))) {
        return false;
      }
    }
    return true;
  }
  onInput(e) {
    if (this.isAndroid && !this.pValidateOnly) {
      let val = this.el.nativeElement.value;
      let lastVal = this.lastValue || '';
      let inserted = this.findDelta(val, lastVal);
      let removed = this.findDelta(lastVal, val);
      let pasted = inserted.length > 1 || !inserted && !removed;
      if (pasted) {
        if (!this.isValidString(val)) {
          this.el.nativeElement.value = lastVal;
          this.ngModelChange.emit(lastVal);
        }
      } else if (!removed) {
        if (!this.isValidChar(inserted)) {
          this.el.nativeElement.value = lastVal;
          this.ngModelChange.emit(lastVal);
        }
      }
      val = this.el.nativeElement.value;
      if (this.isValidString(val)) {
        this.lastValue = val;
      }
    }
  }
  onKeyPress(e) {
    if (this.isAndroid || this.pValidateOnly) {
      return;
    }
    let browser = DomHandler.getBrowser();
    let k = this.getKey(e);
    if (browser.mozilla && (e.ctrlKey || e.altKey)) {
      return;
    } else if (k == 17 || k == 18) {
      return;
    }
    let c = this.getCharCode(e);
    let cc = String.fromCharCode(c);
    let ok = true;
    if (!browser.mozilla && (this.isSpecialKey(e) || !cc)) {
      return;
    }
    ok = this.regex.test(cc);
    if (!ok) {
      e.preventDefault();
    }
  }
  onPaste(e) {
    const clipboardData = e.clipboardData || this.document.defaultView.clipboardData.getData('text');
    if (clipboardData) {
      const pastedText = clipboardData.getData('text');
      for (let char of pastedText.toString()) {
        if (!this.regex.test(char)) {
          e.preventDefault();
          return;
        }
      }
    }
  }
  validate(c) {
    if (this.pValidateOnly) {
      let value = this.el.nativeElement.value;
      if (value && !this.regex.test(value)) {
        return {
          validatePattern: false
        };
      }
    }
  }
}
KeyFilter.ɵfac = function KeyFilter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || KeyFilter)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef));
};
KeyFilter.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: KeyFilter,
  selectors: [["", "pKeyFilter", ""]],
  hostAttrs: [1, "p-element"],
  hostBindings: function KeyFilter_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("input", function KeyFilter_input_HostBindingHandler($event) {
        return ctx.onInput($event);
      })("keypress", function KeyFilter_keypress_HostBindingHandler($event) {
        return ctx.onKeyPress($event);
      })("paste", function KeyFilter_paste_HostBindingHandler($event) {
        return ctx.onPaste($event);
      });
    }
  },
  inputs: {
    pValidateOnly: "pValidateOnly",
    pattern: [0, "pKeyFilter", "pattern"]
  },
  outputs: {
    ngModelChange: "ngModelChange"
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([KEYFILTER_VALIDATOR])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeyFilter, [{
    type: Directive,
    args: [{
      selector: '[pKeyFilter]',
      providers: [KEYFILTER_VALIDATOR],
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.ElementRef
    }];
  }, {
    pValidateOnly: [{
      type: Input
    }],
    ngModelChange: [{
      type: Output
    }],
    pattern: [{
      type: Input,
      args: ['pKeyFilter']
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }],
    onKeyPress: [{
      type: HostListener,
      args: ['keypress', ['$event']]
    }],
    onPaste: [{
      type: HostListener,
      args: ['paste', ['$event']]
    }]
  });
})();
class KeyFilterModule {}
KeyFilterModule.ɵfac = function KeyFilterModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || KeyFilterModule)();
};
KeyFilterModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: KeyFilterModule
});
KeyFilterModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeyFilterModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [KeyFilter],
      declarations: [KeyFilter]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { KEYFILTER_VALIDATOR, KeyFilter, KeyFilterModule };

