import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimBenefitInfo } from '../api-data/claim-benefit-info';
import { ClaimPaymentInformation } from '../api-data/claim-payment-info';
import { ConfigurationService } from 'src/app/configuration.service';
import { StaticInfo } from '../_helpers/static-info';
import { NgIf, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-claim-submit-success',
    templateUrl: './claim-submit-success.component.html',
    styleUrls: ['./claim-submit-success.component.css'],
    imports: [NgIf, TitleCasePipe]
})
export class ClaimSubmitSuccessComponent implements OnInit {
  AllowTrustage:boolean;
  staticInfo: StaticInfo = new StaticInfo();
  brandname: string;
  feedbackForm: string;

/** Gets member's claim and benefit status(es), then redirects to claim page. */
  routeToClaimPage(){
    if (this.claimService.claimBenefit == null) {
      this.claimService.loadClaimPageStatusData().subscribe(
        (data: ClaimBenefitInfo) => {
          this.claimService.claimBenefit = data
        },
        (err) => {
        },
        () => {
          this.getBenefitInformation();
        }
      );
    } else {
      this.claimRouterService.routeToClaimPage();
    }
  }

/** Get member's benefit Information. */
  getBenefitInformation() {
    this.claimService.loadClaimPagePaymentData().subscribe(
      (data: ClaimPaymentInformation) => {
        this.claimService.claimPaymentInformation = data;
        this.claimService.addPaymentInfoToBenefitStatus();
      },
      (err) => {
      },
      () => {
        this.claimRouterService.routeToClaimPage();
      }
    );
  }

  constructor(
    public claimService: ClaimService,
    public claimRouterService: ClaimRouterService,
    private configService: ConfigurationService
  ) { }

  ngOnInit() { 
    if (this.configService.configurationData.featureToggles) {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding), 
      (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
      (this.brandname= this.staticInfo.brandname.trustage,
      this.feedbackForm = this.staticInfo.feedback.feedbackurlTrustage):
      (this.brandname=this.staticInfo.brandname.cmg ,
        this.feedbackForm = this.staticInfo.feedback.feedbackurlcmg)

    } else {
    this.configService.loadFeatureToggleData().toPromise()
    .then(p_configService => {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding),
      (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
      (this.brandname= this.staticInfo.brandname.trustage,
      this.feedbackForm = this.staticInfo.feedback.feedbackurlTrustage):
      (this.brandname=this.staticInfo.brandname.cmg,
        this.feedbackForm = this.staticInfo.feedback.feedbackurlcmg)
    })
    .catch(err => console.error(err));
  }

  }

}
