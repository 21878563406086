import { Component, OnInit, Input } from '@angular/core';
import { StaticInfo } from '../_helpers/static-info';
import { PaymentHistory } from '../api-data/claim-payment-info';
import cssVars from 'css-vars-ponyfill';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';
import { NgIf, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-benefit-payment-info',
    templateUrl: './benefit-payment-info.component.html',
    styleUrls: ['./benefit-payment-info.component.css'],
    imports: [NgIf, FieldsetModule, SharedModule, TableModule, CurrencyPipe, DatePipe]
})
export class BenefitPaymentInfoComponent implements OnInit {

  @Input() benefitStatus;

  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  header = this.staticInfo.header;
  ps = this.staticInfo.paymentStatus
  benefitStatuses = [];

  /** Adds additional rows (10) to Past Benefits */
  getNext10PastPayments() {
    this.benefitStatus.rowCount += 10;
  }

  /** Returns the most recent payment of the claim. */
  getMostRecentPayment(paymentsHistory: PaymentHistory[]) {
    return this.getPaidPayments(paymentsHistory).shift();
  }

  /** Returns past payments of the claim (excluding the most recent payment). */
  getPastPayments(paymentsHistory: PaymentHistory[]) {
    let pastPayments = this.getPaidPayments(paymentsHistory);
    pastPayments.shift();
    return pastPayments;
  }

  /** Returns future payments that are within 6 months ahead. */
  getFuture6MonthPayments(paymentsHistory: PaymentHistory[]) {
    let todayPlus6Months = new Date()
    todayPlus6Months.setMonth(todayPlus6Months.getMonth() + 6)

    return paymentsHistory.filter(element => {
      if (element.PaymentStatus.toUpperCase() == this.ps.payable.code.toUpperCase()
        && new Date(element.PaymentInitiationDate) < todayPlus6Months) {
        return element;
      }
    }).reverse();
  }

  /** Returns all paid payments. */
  getPaidPayments(paymentsHistory: PaymentHistory[]) {
    return paymentsHistory.filter(element => {
      if (element.PaymentStatus.toUpperCase() == this.ps.paid.code.toUpperCase()) {
        return element;
      }
    });
  }

  constructor(
  ) { }

  ngOnInit() {
    cssVars();
  }

}
