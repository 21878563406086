import * as i0 from '@angular/core';
import { Directive, Optional, HostListener, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/forms';
class InputText {
  constructor(el, ngModel, cd) {
    this.el = el;
    this.ngModel = ngModel;
    this.cd = cd;
  }
  ngAfterViewInit() {
    this.updateFilledState();
    this.cd.detectChanges();
  }
  ngDoCheck() {
    this.updateFilledState();
  }
  onInput(e) {
    this.updateFilledState();
  }
  updateFilledState() {
    this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length || this.ngModel && this.ngModel.model;
  }
}
InputText.ɵfac = function InputText_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || InputText)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.NgModel, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
InputText.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: InputText,
  selectors: [["", "pInputText", ""]],
  hostAttrs: [1, "p-inputtext", "p-component", "p-element"],
  hostVars: 2,
  hostBindings: function InputText_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("input", function InputText_input_HostBindingHandler($event) {
        return ctx.onInput($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("p-filled", ctx.filled);
    }
  },
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputText, [{
    type: Directive,
    args: [{
      selector: '[pInputText]',
      host: {
        class: 'p-inputtext p-component p-element',
        '[class.p-filled]': 'filled'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.NgModel,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }]
  });
})();
class InputTextModule {}
InputTextModule.ɵfac = function InputTextModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || InputTextModule)();
};
InputTextModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: InputTextModule
});
InputTextModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputTextModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [InputText],
      declarations: [InputText]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputText, InputTextModule };

