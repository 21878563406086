import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
import { DomHandler } from 'primeng/dom';
class AutoFocus {
  constructor(host) {
    this.host = host;
    this.focused = false;
  }
  ngAfterViewChecked() {
    if (!this.focused) {
      if (this.autofocus) {
        const focusableElements = DomHandler.getFocusableElements(this.host.nativeElement);
        if (focusableElements.length === 0) {
          this.host.nativeElement.focus();
        }
        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
        this.focused = true;
      }
    }
  }
}
AutoFocus.ɵfac = function AutoFocus_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AutoFocus)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
AutoFocus.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AutoFocus,
  selectors: [["", "pAutoFocus", ""]],
  hostAttrs: [1, "p-element"],
  inputs: {
    autofocus: "autofocus"
  },
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocus, [{
    type: Directive,
    args: [{
      selector: '[pAutoFocus]',
      host: {
        class: 'p-element'
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    autofocus: [{
      type: Input
    }]
  });
})();
class AutoFocusModule {}
AutoFocusModule.ɵfac = function AutoFocusModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AutoFocusModule)();
};
AutoFocusModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AutoFocusModule
});
AutoFocusModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocusModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [AutoFocus],
      declarations: [AutoFocus]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AutoFocus, AutoFocusModule };

