import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { InternationalAddressService } from '../international-address.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { InputTextModule } from 'primeng/inputtext';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { NgIf, NgFor } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-life-hosp',
    templateUrl: './form-life-hosp.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-life-hosp.component.css'],
    imports: [NgIf, CalendarModule, DateMaskDirective, FormsModule, InputTextModule, NgSelectModule]
})
export class FormLifeHospComponent implements OnInit {

  @Input() claimForm: NgForm
  allowHospitalizationLossType: boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowHospitalizationLossType);

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public internationalAddressService: InternationalAddressService,
    private configService: ConfigurationService
  ) {
  }

  ngOnInit() {
    cssVars();
  }

  isForeignCountry(countryCode: string){
    this.internationalAddressService.isClaimInternational(countryCode);
  }
}
