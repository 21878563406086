import { Component, OnInit } from '@angular/core';
import { StaticInfo } from '../_helpers/static-info';
import { RoutesStaticInfo } from '../_helpers/routes-static-info';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ClaimService } from '../claim.service';
import { ClaimChannel } from '../enum-claim-channel';
import { ClaimRouterService } from '../claim-router.service';
import { SharedModule } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';

@Component({
    selector: 'app-community-resources',
    templateUrl: './community-resources.component.html',
    styleUrls: ['./community-resources.component.css'],
    imports: [FieldsetModule, SharedModule]
})
export class CommunityResourcesComponent implements OnInit {

  staticInfo = new StaticInfo();
  faqContact = this.staticInfo.faqContact;
  urlPath = RoutesStaticInfo;
  memberRegistrationPhoneNumber = this.staticInfo.contactUsPhoneNumber.memberRegistrationClaimChannel
  claimChannel = ClaimChannel;
  gtmFaq = "close";

  /** Directs user to claim page. */
  directToClaimPage(){
    this.claimRouterService.routeToClaimPage()
  }

  constructor(
    public contactUs: ContactUsComponent,
    public claimService: ClaimService,
    private claimRouterService: ClaimRouterService
    ) { }

  ngOnInit() {
  }
}
