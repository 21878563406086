import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { StaticInfo } from '../_helpers/static-info';
import { ClaimChannel } from '../enum-claim-channel';
import { LossType } from '../claim-data/enum-losstype';
import { RoutesStaticInfo } from '../_helpers/routes-static-info';
import { ClaimStepsService } from '../claim-steps.service';
import { ClaimRouterService } from '../claim-router.service';
import { ConfigurationService } from '../configuration.service';
import { FormWorkUnemploymentComponent } from '../form-work-unemployment/form-work-unemployment.component';
import { FormWorkComponent } from '../form-work/form-work.component';
import { NgIf } from '@angular/common';
import { FormStepsComponent } from '../form-steps/form-steps.component';

@Component({
    selector: 'app-claimform-work',
    templateUrl: './claimform-work.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./claimform-work.component.css'],
    imports: [FormStepsComponent, FormsModule, NgIf, FormWorkComponent, FormWorkUnemploymentComponent]
})
export class ClaimformWorkComponent implements OnInit {

  @Input() claimForm: NgForm

  workPage = 1;
  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  errMsg = this.staticInfo.errorMessage;
  claimChannel = ClaimChannel;
  lossType = LossType;
  routesUrl = RoutesStaticInfo;
  allowUnemploymentPostRegistration:boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowUnemploymentPostRegistration);

  workSubmit(isFormValid){
    let route;
    if(isFormValid){
      switch (this.claimService.lossType) {
        case this.lossType.Disability: {
          route = this.routesUrl.claimMedical;

          // Only PostRegistration should do this at this time
          if (this.claimService.claimChannel === ClaimChannel.PostRegistration){
            this.claimformService.checkPreExisting();
          }
          break;
        }
        case this.lossType.Unemployment: {
          route = this.routesUrl.summary;
          break;
        }
      }
      this.claimRouterService.workSubmit(isFormValid, route);
    }
  }

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public claimStepsService: ClaimStepsService,
    public claimRouterService: ClaimRouterService,
    public configService: ConfigurationService
  ) { }

  ngOnInit() {
    this.claimStepsService.index = this.workPage;
    cssVars();
  }

}
