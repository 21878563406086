import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { StaticInfo } from '../_helpers/static-info';
import cssVars from 'css-vars-ponyfill';
import { environment } from '../../environments/environment';
import { ClaimRouterService } from '../claim-router.service';
import { BenefitStatus } from '../api-data/claim-benefit-info';
import { LossType } from '../claim-data/enum-losstype';
import { ClaimChannel } from '../enum-claim-channel';
import { ConfigurationService } from 'src/app/configuration.service';
import { BenefitPaymentInfoComponent } from '../benefit-payment-info/benefit-payment-info.component';
import { AccordionModule } from 'primeng/accordion';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-claim-page',
    templateUrl: './claim-page.component.html',
    styleUrls: ['./claim-page.component.css'],
    imports: [NgIf, AccordionModule, NgFor, BenefitPaymentInfoComponent]
})

export class ClaimPageComponent implements OnInit {

  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  header = this.staticInfo.header;
  staticInfo_benefitStatus = this.staticInfo.benefitStatus;
  ps = this.staticInfo.paymentStatus;
  benefitStatuses = [];
  environment = environment;
  allowUnemploymentPostRegistration: boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowUnemploymentPostRegistration);
  allowHospitalizationLossType: boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowHospitalizationLossType);

  constructor(
    public claimService: ClaimService,
    public claimRouterService: ClaimRouterService,
    private configService: ConfigurationService
  ) { }

  ngOnInit() {
    this.addRowCountToBenefitStatuses();
    cssVars();
  }

  /** Returns true if the benefitStatus has paymentsHistory, false if it doesn't. */
  hasPaymentsHistory(paymentHistoryLength:number):boolean{
    return paymentHistoryLength > 0
  }

  addRowCountToBenefitStatuses(){
    this.benefitStatuses = this.claimService.claimBenefit.BenefitStatuses
    this.benefitStatuses.filter(e => {
      e.rowCount = 10
    })
  }

  showInitialClaimLink(benefitStatuses: BenefitStatus[]) {
    let infoReceived: number = 0;
    benefitStatuses.forEach((value) => {
      if (value.MemberFriendlyStatus != this.staticInfo_benefitStatus.informationNeeded
        || value.ClaimsExpressStatus != this.staticInfo_benefitStatus.registered)
        infoReceived++;
    })

      return this.initialCaimLinkLossTypeCheck(this.claimService.lossType, this.allowUnemploymentPostRegistration, this.allowHospitalizationLossType)
      && !this.claimService.claimHasBeenSubmitted
      && infoReceived == 0;
  }

  private initialCaimLinkLossTypeCheck(lossType: string, allowUnemploymentPostRegistration: boolean, allowHospitalizationLossType: boolean) : boolean {
    let result : boolean = false;

    if (!result && lossType.toUpperCase() == LossType.Disability.toUpperCase()) {
      result = true;
    }
    if (!result && allowUnemploymentPostRegistration && lossType.toUpperCase() == LossType.Unemployment.toUpperCase()) {
      result = true;
    }
    if(!result && allowHospitalizationLossType && lossType.toUpperCase() == LossType.Hospitalization.toUpperCase()){
      result = true;
    }

    return result;
  }

  showSupplementalClaimLink(benefitStatuses: BenefitStatus[]) {
    let infoNeeded: number = 0;
    benefitStatuses.forEach((value) => {
      if (value.MemberFriendlyStatus == this.staticInfo_benefitStatus.SupplementalInformationNeeded)
      infoNeeded++;
    })

    return (this.claimService.lossType.toUpperCase() == LossType.Disability.toUpperCase() ||
      this.claimService.lossType.toUpperCase() == LossType.Unemployment.toUpperCase())
      && infoNeeded > 0;
  }

  routeToInitialClaim(){
    this.claimService.setEnumClaimChannel(ClaimChannel.PostRegistration);
    this.claimRouterService.routeToContact();
  }

  routeToSupplementalClaim(){
    this.claimService.setEnumClaimChannel(ClaimChannel.Supplemental);
    this.claimRouterService.routeToSupplemental();
  }

}
