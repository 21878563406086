import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import { ClaimService } from '../claim.service';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { NgIf, NgFor } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgSelectModule } from '@ng-select/ng-select';


@Component({
    selector: 'app-form-supp-simple-iu',
    templateUrl: './form-supp-simple-iu.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-supp-simple-iu.component.css'],
    imports: [RadioButtonModule, FormsModule, NgIf, CalendarModule, DateMaskDirective, NgFor, NgSelectModule]
})
export class FormSuppSimpleIuComponent implements OnInit {

  @Input() claimForm: NgForm

  _showSeekingEmploymentQuestion:boolean = false
  
  counter(i: number) {
    return new Array(i);
  }

  hoursWorkingLessThan25():boolean{
    if(this.claimService.claimRoot.claim.employment.hoursPerWeek == null){
      return false;
    }
    return this.claimService.claimRoot.claim.employment.hoursPerWeek < 25
  }

  showSeekingEmploymentQuestion(){
    this._showSeekingEmploymentQuestion = 
      (this.claimService.claimRoot.claim.employment.hasReturnedToWork === true && this.hoursWorkingLessThan25()) || 
      (this.claimService.claimRoot.claim.employment.hasReturnedToWork === false && (this.claimService.acpValues.plansToWorkWithSameEmployer === this.claimformService.staticInfo.no || this.claimService.acpValues.plansToWorkWithSameEmployer === this.claimformService.staticInfo.unsure))
    
    if(this._showSeekingEmploymentQuestion === false){
      this.claimService.acpValues.isSeekingEmployment = null
    }

    return this._showSeekingEmploymentQuestion
    }


  constructor(
    public claimformService: ClaimformService,
    public claimService: ClaimService
  ) { }

  ngOnInit() {
  }

}
