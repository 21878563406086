import { Component } from '@angular/core';
import cssVars from 'css-vars-ponyfill';
import { ClaimService } from './claim.service';
import { Router, NavigationEnd, ActivatedRoute, RouterOutlet } from '@angular/router';
import { ClaimChannel } from './enum-claim-channel';
import { SessionService } from './session.service';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap }from'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { SessionComponent } from './session/session.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { NgIf } from '@angular/common';
import { MainHeaderComponent } from './main-header/main-header.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    imports: [MainHeaderComponent, NgIf, AnnouncementsComponent, RouterOutlet, ContactUsComponent, SessionComponent, MainFooterComponent]
})

export class AppComponent {

  /** Scrolls to the top of the page. */
  scrollToTop() {
    window.scrollTo(0, 0)
  }

  constructor(
    private claimService: ClaimService,
    private router: Router,
    private sessionService: SessionService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    public configService: ConfigurationService
    ) {
      // Push the claim channel and loss type on page changes when they have a value
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd){
          let queryParam = this.claimService.claimChannel === ClaimChannel.MemberRegistration ? this.claimService.contractNumber : this.claimService.trackingNumber;
        }
      });
    }

  ngOnInit() {
    cssVars();

    // Set the page title property for great good :)
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
      ).subscribe((event) => this.titleService.setTitle(event['title']))
  }
}
