import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { ClaimformService } from '../claimform.service';
import { ConfigurationService } from '../configuration.service';
import { FraudLanguageComponent } from '../fraud-language/fraud-language.component';
import { FormDocumentComponent } from '../form-document/form-document.component';
import { FormSuppUnemploymentComponent } from '../form-supp-unemployment/form-supp-unemployment.component';
import { FormSuppSimpleIuComponent } from '../form-supp-simple-iu/form-supp-simple-iu.component';
import { FormSuppDisabilityComponent } from '../form-supp-disability/form-supp-disability.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-claimform-supplemental',
    templateUrl: './claimform-supplemental.component.html',
    styleUrls: ['./claimform-supplemental.component.css'],
    imports: [FormsModule, NgIf, FormSuppDisabilityComponent, FormSuppSimpleIuComponent, FormSuppUnemploymentComponent, FormDocumentComponent, FraudLanguageComponent]
})
export class ClaimformSupplementalComponent implements OnInit {

  showSimpleIU:boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showSimpleIU);

  /** When user submits a valid form, this calls submitClaim from ClaimService. */
  onSubmit(isFormValid: boolean) {
    if (isFormValid) {
      this.claimformService.submitClaimSubscribe();
    }
  }

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public configService: ConfigurationService
  ) { }

  ngOnInit() {
    if (this.claimService.claimHasBeenSubmitted) {
      this.claimformService.clearSupplementalData();
    }
  }

}
