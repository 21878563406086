import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { StaticInfo } from '../_helpers/static-info';
import { Physician } from '../claim-data/physician';
import cssVars from 'css-vars-ponyfill';
import { ClaimformService } from '../claimform.service';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-form-providers',
    templateUrl: './form-providers.component.html',
    styleUrls: ['./form-providers.component.css'],
    imports: [NgIf, FormsModule, InputTextModule, InputMaskModule, ButtonModule]
})
export class FormProvidersComponent implements OnInit {

  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;

  /** When user clicks button for additional health care provider, this shows the user the next available provider fields up to 5. */
  showAdditionalProviders() {
    if (this.claimformService.showProvider.two == false) {
      this.claimService.claimRoot.claim.incident.physician2 = new Physician()
      this.claimformService.showProvider.two = true;
    } else if (this.claimformService.showProvider.three == false) {
      this.claimService.claimRoot.claim.incident.physician3 = new Physician()
      this.claimformService.showProvider.three = true;
    } else if (this.claimformService.showProvider.four == false) {
      this.claimService.claimRoot.claim.incident.physician4 = new Physician()
      this.claimformService.showProvider.four = true;
    } else if (this.claimformService.showProvider.five == false) {
      this.claimService.claimRoot.claim.incident.physician5 = new Physician()
      this.claimformService.showProvider.five = true;
    }
  }

  constructor(
    private claimService: ClaimService,
    public claimformService: ClaimformService
    ) { }

  ngOnInit() {
    cssVars();
  }

}
